<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold">Registro de evento</h2>
      </b-col>
    </b-row>

    <b-row class="teacher-profile mt-50">
      <b-col>
        <b-row>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Título</label>
              <b-form-input
                v-model="event.title"
                type="text"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Disponível até</label>
              <b-form-input
                v-model="event.available_until"
                type="date"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col class="text-left" v-if="event.attachment_url">
            <b-form-group description>
              <label required for="label text-left">Anexo</label>
              <div class="wrap-button">
                <b-col class="text-right">
                  <a
                    target="_blank"
                    :href="event.attachment_url"
                    class="upload btn"
                    >Acessar anexo</a
                  >
                </b-col>
                <b-col class="text-right">
                  <b-button @click="removeAttachment" class="large pending"
                    >Remover anexo</b-button
                  >
                </b-col>
              </div>
            </b-form-group>
          </b-col>

          <b-col class="text-left" v-else>
            <b-form-group description>
              <label required for="label text-left">Anexo</label>
              <b-form-file
                accept=".pdf"
                :value="file"
                @input="(f) => setFile(f)"
                plain
                required
              >
              </b-form-file>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-8">
            <b-form-group class="required" description>
              <label for="label">Descrição</label>
              <b-form-textarea
                v-model="event.description"
                type="textarea"
                placeholder
                required
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col class="col-4">
            <b-form-group
              class="required"
              label="Precisa de autorização dos responsáveis?"
              description
            >
              <b-form-radio-group v-model="event.auth_is_required">
                <b-form-radio value="true" placeholder>Sim</b-form-radio>
                <b-form-radio value="false" placeholder required
                  >Não</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>

        <label for="label">Destinatários</label>
        <b-row>
          <b-col>
            <SelectSimple
              v-model="selectedTarget"
              :options="targetsOptions"
              :callback="selectTargets"
            />
          </b-col>
          <b-col v-if="allCycles.length && isSubject">
            <SelectSimple
              :callback="selectSubject"
              :options="allCycles"
              v-model="selectedCycle"
            />
          </b-col>
          <b-col>
            <select-multiple
              v-if="
                selectedTargetDetailsOptions &&
                selectedTargetDetailsOptions.length > 0
              "
              :callback="selectTargetsDetails"
              :options="selectedTargetDetailsOptions"
              :hasSelectAll="false"
              :initial="selectedTargetDetails"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row align-v="center" class="mt-5">
      <b-col class="text-right">
        <b-button
          class="large blue outline"
          :to="{
            name: 'eventos',
          }"
          >Voltar</b-button
        >

        <b-button :disabled="!formFilled" @click="submit" class="large blue"
          >Criar evento</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import httpRda from "../../../http-rda.js";
import SelectSimple from "@/components/inputs/SelectSimple.vue";
import SelectMultiple from "@/components/inputs/SelectMultiple.vue";

export default {
  name: "updateEvent",
  components: {
    SelectSimple,
    SelectMultiple,
  },

  data() {
    return {
      formFilled: false,
      event: null,
      isSubject: false,
      allCycles: [],
      selectedCycle: null,
      targets: [],
      attachment: null,
      file: null,
      targetsOptions: [
        { id: "Cycle", label: "Ciclos" },
        { id: "Classroom", label: "Turmas" },
        { id: "Subject", label: "Disciplinas" },
        { id: "Student", label: "Alunos" },
      ],
      targetsValues: null,
      selectedTarget: null,

      targetDetails: null,
      selectedTargetDetails: null,
      selectedTargetDetailsOptions: null,

      breadcrumb: [
        {
          text: "Administração",
          href: "/administracao",
        },
        {
          text: "Eventos",
          href: "/administracao/eventos",
        },
        {
          text: "Registro de evento",
          href: "novosusuario",
          active: true,
        },
      ],
    };
  },
  watch: {
    event: {
      handler: function (newEvent) {
        this.updateFormFilled(newEvent, this.targets);
      },
      deep: true,
    },
    selectedTargetDetails: {
      handler: function (newTargets) {
        this.updateFormFilled(this.event, newTargets);
      },
      deep: true,
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },

  methods: {
    updateFormFilled(event, targets) {
      this.formFilled =
        event &&
        event.title &&
        event.description &&
        event.available_until &&
        event.auth_is_required &&
        targets.length > 0;
    },

    getEvent() {
      this.event = {
        title: "",
        description: "",
        available_until: "",
        auth_is_required: "",
        attachment_url: "",
      };
    },

    async setFile(file) {
      this.file = file;
    },

    async submitFile(id) {
      if (this.file) {
        const bodyFormData = new FormData();
        bodyFormData.append("fileName", this.file.name);
        bodyFormData.append("fileType", "attachment_url");
        bodyFormData.append("file", this.file);

        try {
          await httpRda
            .patch(`/events/${id}`, bodyFormData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => (this.file.url = response.data.url));
        } catch (error) {
          alert(error);
        }

        this.getEvent();
      }
    },

    async removeAttachment() {
      try {
        const { value: accept } = await this.$swal.fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, remover!",
          cancelButtonText: "Não, cancelar!",
          reverseButtons: true,
        });

        if (!accept) {
          return;
        }

        await httpRda
          .patch(`/events/${this.id}`, { attachment_url: null })
          .then(() => (this.file = null));
      } catch (error) {
        alert(error);
      }

      this.getEvent();
    },

    async submit() {
      try {
        const payload = { ...this.event, targets: this.targets };

        await httpRda.post(`/events`, payload).then(async (response) => {
          if (this.file) {
            await this.submitFile(response.data.event.id);
          }
        });
        this.$router.push({ name: "eventos" });
      } catch (error) {
        alert(error);
      }
    },

    async selectTargets(params) {
      this.selectedTarget = params;
      this.selectedTargetDetails = [];
      this.isSubject = false;

      switch (this.selectedTarget.label) {
        case "Ciclos":
          this.targetDetails = await httpRda
            .get("/cycles")
            .then((response) => response.data.cycles);

          this.selectedTargetDetailsOptions = this.targetDetails.map(
            (item) => ({ id: item.id, name: item.designation })
          );

          break;

        case "Turmas":
          this.targetDetails = await httpRda
            .get("/classrooms?include=Students")
            .then((response) => response.data.classrooms);

          this.selectedTargetDetailsOptions = this.targetDetails.map(
            (item) => ({ id: item.id, name: item.designation })
          );

          break;

        case "Disciplinas":
          this.isSubject = true;
          this.allCycles = await httpRda.get("/cycles").then((response) => {
            return response.data.cycles.map((item) => ({
              label: item.designation,
              id: item.id,
            }));
          });

          break;

        case "Alunos":
          this.targetDetails = await httpRda
            .get("/students?is_active=true")
            .then((response) => response.data.students);

          this.selectedTargetDetailsOptions = this.targetDetails.map(
            (student) => ({
              id: student.User.id,
              name: student.User.name,
            })
          );
          break;

        default:
          break;
      }
    },

    async selectSubject(params) {
      this.selectedCycle = params;
      this.selectedTargetDetails = [];
      await httpRda
        .get(`cycles/${params.id}?include=subjects`)
        .then((response) => {
          this.selectedTargetDetailsOptions = response.data.cycle.Subjects.map(
            (item) => ({ id: item.id, name: item.designation })
          );
        });
    },

    async selectTargetsDetails(params) {
      this.selectedTargetDetails = params;
      this.targets = [];
      this.targets.push({
        [this.selectedTarget.id]: this.selectedTargetDetails.map(
          (item) => item.id
        ),
      });
    },
  },

  created() {
    this.getEvent();
  },
};
</script>

<style lang="scss">
.teacher-profile {
  .form-group {
    &.required {
      &:after {
        right: 0;
      }
    }
  }
}

.table-students {
  width: 100%;

  td,
  th {
    font-size: 18px;
  }

  th {
    padding-bottom: 20px;
  }

  td {
    padding: 20px 0;
  }

  tr td:last-of-type {
    text-align: right;

    .actions {
      span {
        cursor: pointer;
      }

      .deleteStudent {
        margin-left: 30px;
      }
    }
  }

  tr + tr {
    border-bottom: 1px solid $grey5;
  }
}

.title-collapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  h5 {
    flex: 1;
  }

  .btn {
    background: transparent;
    border: 0;

    svg {
      font-size: 16px;
      fill: red;
    }
  }
}

.wrap-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  span {
    background-color: #efefef;
    padding: 4px 8px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.wrap-button {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}

.tag {
  background-color: #68c5b7;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  padding: 0 3px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
}

.container-rda .multiselect__tag-icon:after {
  color: white;
}

input.isInvalid {
  border-color: red;
}
</style>
